@import '/src/index.scss';

.consignmentDetailsContainer{
    @extend .columnListCard;
    padding: 10px;
    margin: 0;
    width: 100%;
    .id{
        color: var(--secondary-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value{
            font-weight: bold;
            font-size: 16px;
            color: var(--font_color_alt);
        }
        .isDelayed{
            background: red;
            color: white;
            padding: 5px var(--padding_default);
            border-radius: var(--border_radius);
            margin: var(--margin_default) 0;
            width: fit-content;
        }
    }
    .statusWrap{
        height: 150px;
        width: 100%;
        margin-bottom: 10px;
    }
    .statusMeta{
        padding: 5px 0;
        display: flex;
        justify-content: center;
        color: var(--font_color_alt);
    }
    .quantity{
        display: flex;
        border-radius: var(--border_radius);
        padding: var(--padding_custom);
        margin: var(--margin_default) 0;
        align-items: center;
        .icon{
            width: fit-content;
            height: fit-content;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.178);
            margin-right: 20px;
            padding: 15px;
            img{
                width: 35px;
                height: 35px;
            }
        }
    }
    .variety{
        padding: var(--padding_custom);
        background: var(--primary-color-light_alt);
        color: var(--secondary-color);
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: var(--border_radius);
        .flexColumn{
            align-items: center;
        }
        .name{
            font-size: 14px;
            font-weight: 600;
            color: var(--font_color_alt);
        }
    }
    .exportMeta{
        margin: var(--margin_default) 0;
        padding: var(--padding_custom);
        background: var(--background-secondary);
        border-radius: var(--border_radius);
    }
    .exporter{
        padding: var(--padding_default) 0;
        .name{
            font-size: 14px;
            color: var(--font_color);
            font-weight: 600;
        }
        .country, .mode{
            color: var(--secondary-color);
            b{
                color: var(--font_color);
            }
        }
    }
    .dates{
        padding: var(--padding_default) 0;
        .contract, .delivery{
            font-size: 14px;
            color: var(--secondary-color);
            b{
                color: var(--font_color);
            }
        }
    }
}
