@import '/src/index.scss';

.cardList{
    @extend .columnListCard !optional;
    display: block;
    height: calc(100% - 20px);
    .cardListHeader{
        height: 110px;
        width: 100%;
    }
    .cardListInner{
        width: 100%;
        overflow: auto;
    }
    .filterContainer{
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .filteredContent{
        height: calc(100% - 150px);
    }
    .filterlessCard{
        height: calc(100% - 110px);
    }
}

.customCard{
    padding: var(--padding_custom);
    color: var(--font_color);
    background: var(--background-secondary);
    margin: var(--margin_default) 20px;
    border-radius: var(--border_radius);
    &:hover{
        cursor: pointer;
        transition: all 0.7s ease-in-out;
        background: var(--primary-color-light_alt);
    }
    @media screen and (max-width: 768px) {
        margin: var(--margin_default);
    }
}
.delayedCard{
    background: #fde6e6;
}


.emptyCard{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .emptyMsg{
        @extend .statusDesc;
        margin: 40px 0;
        color: red;
        background: rgb(248, 222, 222);
    }
}
.filterContainer{
    .filterButton{
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 20px;
        color: #ffa500;
        cursor: pointer;
        font-weight: bold;
        img{
            height: 15px;
            width: 15px;
            margin: 0 5px;
        }
    }
    .filterOverlay{
        height: fit-content;
        top: 5px;
        left: 0;
        width: 100%;
        border-radius: var(--border_radius);
        background: white;
        -webkit-box-shadow: var(--box-shadow);
        -moz-box-shadow: var(--box-shadow);
        box-shadow: var(--box-shadow);
        .stageFilters{
            padding: var(--padding_custom);
            color: var(--font_color);
            padding-top: 30px;
            width: 100%;
            .stageFilter{
                cursor: pointer;
                margin: 5px 0;
                text-transform: capitalize;
                background: var(--border-color);
                padding: 2px 5px;
                font-size: 11px;
                border-radius: 5px;
                width: 100%;
                &:hover{
                    color: var(--font_color_alt);
                }
                .percentageBar{
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: rgb(255 166 0 / 22%);
                    border-radius: 5px;
                    z-index: 10;
                }
            }
            .closeIcon{
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                height: 20px;
                width: 20px;
            }
        }
    }
}