@import '/src/index.scss';

.submittedDataArray{
    padding: var(--padding_default);
    border: 1px solid var(--border-color);
    border-radius: var(--border_radius);
    margin: var(--margin_default) 0;
    background: var(--background-secondary);
    h6{
        padding: var(--padding_default);
    }
}
.submittedData{
    border-radius: var(--border_radius);
    background: var(--primary-color-light_alt);
    padding: var(--padding_custom);
    color: var(--font_color);
    margin: 5px 0;
    .header{
        font-weight: bold;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        color: var(--font_color_alt);
    }
    .dataGroupBody{
        padding: var(--padding_default);
        color: var(--font_color_alt);
        background: rgba(255, 255, 255, 0.548);
        border-radius: var(--border_radius);
        .dataItem{
            padding: 0 var(--padding_default);
        }
    }
}