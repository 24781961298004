.container{width:100%;height:90vh}
.details-wrapper {
  overflow: hidden;
  padding: 30px 10px 10px;
  position: absolute;
  right: 5px;
  top: 30px;
}
.details{
  width: 375px;
  background: #fbfbfbf2;
  border-radius: 5px;
  box-shadow: 0 2px 10px #999;
  color: #999;
  float: right;
  position: relative;
  width: 350px;
  z-index: 1000;
  padding: 20px;
}
.details-header{
  border-bottom: 4px solid #999;
  margin: 0.41667em auto 0.83333em;
  overflow: hidden;
  padding: 0 0 0.83333em 0;
  font-size: 18px;
  text-transform: uppercase;
}
.batch{
  cursor: pointer;
  text-decoration: underline;
}
.batch.selected{
  color: #f78e1e;
}