@import '/src/index.scss';

.walletContainer{
    @extend .columnListCard !optional;
    background: var(--primary-color-light_alt);
    border: 1px solid var(--font_color_alt);
    width: calc(100%);
    padding: var(--padding_custom);
    margin: 0;
    .walletBody{
        padding: 20px 0 0;
        height: 100%;
    }
}

.disclaimer{
    padding: var(--padding_custom);
    font-size: 12px;
    color: var(--font_color);
}

.clickable{
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
    img{
        width: 15px;
        height: 15px;
        transform: rotate(180deg);
    }
}
.balanceSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .meta{
        color: var(--secondary-color);
    }
    .value{
        font-size: 22px;
        color: var(--font_color_alt);
        font-weight: bold;
    }
    .usedValue{
        color: orange !important;
    }
}

.historyContainer{
    border-radius: var(--border_radius);
    // padding: var(--padding_default);
    background: rgba(255, 255, 255, 0.726);
    .historyTitle{
        font-size: 13px;
        font-weight: bold;
        color: var(--font_color);
        padding: var(--padding_custom);
        border-bottom: 1px solid var(--border-color);
    }
    .historyBody{
        max-height: 50vh;
        overflow: auto;
        .historyItem{
            align-items: center;
            padding: var(--padding_custom);
            &:hover{
                cursor: pointer;
                background: var(--background-primary);
            }
            .itemName{
                h6{
                   margin-bottom: 5px; 
                }
                .meta{
                    font-size: 11px;
                    color: var(--secondary-color);
                }
            }
            .itemAmount{
                font-size: 14px;
                font-weight: bold;
            }
            .credit{
                color: green;
            }
            .debit{
                color: red;
            }
        }
    }
}