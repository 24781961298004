@import '/src/index.scss';

.farmcard{
    @extend .columnListCard;
    background: rgb(249 249 249 / 85%);
    width: 100%;
    padding: var(--padding_custom);
    margin-left: 0;
    margin-right: 0;
    border: 1px solid var(--border-color);
    .header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .farmData{
            .meta{
                color: var(--secondary-color);
                padding: 2px 0;
                img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
            .location{
                display: flex;
                align-items: center;
            }
            h6{
                color: var(--secondary-color);
                display: flex;
                align-items: baseline;
                b{
                    color: var(--font_color);
                }
                .meta{
                    font-size: 12px;
                    color: var(--font_color_alt);
                    padding-left: 10px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }

        .tempAllocations{
            display: flex;
            align-items: end;
            justify-content: start;
            padding: var(--padding_default);
        }
        
        .treeData{
            display: flex;
            align-items: center;
            background: var(--primary-color-light_alt);
            padding: var(--padding_custom);
            border-radius: var(--border_radius);
            .imgWrap{
                border-radius: 50%;
                padding: 7px;
                margin-right: 10px;
                background: rgba(255, 255, 255, 0.288);
                img{
                    width: 40px;
                    height: 40px;
                }
            }
            .metaGroup{
                .meta{
                    color: var(--secondary-color);
                    b{
                        color: var(--font_color_alt);
                    }
                }
            }
        }
    }
    &:hover{
        cursor: pointer;
        background: var(--background-primary);
    }
}

.selectedFarmCard{
    background: var(--primary-color-light_alt);
    .header{
        .treeData{
            background: var(--primary-color-light);
        }
    }
}
.activeFarmCard{
    border: 2px solid var(--primary-color-light);
}


@media screen and (max-width: 768px) {
    .treeData{
        .imgWrap{
            display: none;
        }
    }
}