@import url('../../../index.scss');

.footerMain {
    height: 200px;
    background: var(--font_color);
    padding: var(--padding_default);
    color: var(--primary-color-light_alt);
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .divider {
        height: 2px;
        background-color: var(--primary-color-light_alt);
    }

    .dividerHorizontal {
        width: 2px;
        height: 60%;
        margin: auto 0;
        background: var(--primary-color-light_alt);
    }

    .section1 {
        display: flex;
        flex-direction: row;
        // align-items: flex-end;
        justify-content: center;
        // width: 90%;
        margin: var(--margin_default);
        font-size: 13px;
    }

    .section2 {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        align-items: center;
    }

    .devText {
        display: flex;
        margin: var(--margin_default);
        color: var(--primary-color-light_alt);
        justify-content: center;
        align-items: center;
        text-align: center;

        .txt {
            font-weight: 800;
            font-size: 14px;
        }

        b {
            color: var(--primary-color-light_alt);
            text-decoration: none;
            margin: 0 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 1000px) {

    .devText {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        text-align: center;
    }

}