

.statusCard{
    background: lightgrey;
    border-radius: var(--border_radius);
    padding: 0 40px;
    margin-bottom: var(--margin_default);   
    color: white; 
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    .statusImgWrap{
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.178);
        margin-right: 20px;
        padding: 12px;
        img{
            width: 28px;
            height: 28px;
        }
    }
    .statusInfo{
        display: flex;
        flex-direction: column;
        .count{
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
        }
        .info{
            text-transform: capitalize;
            font-size: 14px;
        }
    }
    .value{
        color: white;
        .metcount{
            font-size: 14px;
            color: red;
        }
        .count{
            font-size: 26px;
        }
    }
}


@media screen and (max-width: 768px) {
    .statusCard{
        padding: 0px 30px;
        .statusInfo{
            .count{
                font-size: 28px;
            }
            .info{
                font-size: 11px;
            }
        }
    }
}