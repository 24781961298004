

.allocationMeta{
    padding: var(--padding_custom);
    border-radius: var(--border_radius);
    margin-top: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .metaGroup{
        display: flex;
        flex-direction: column;
        .metaItem{
            color: var(--font_color);
            b{
                color: white;
            }
        }
        .quantityGroup{
            background: rgba(255, 255, 255, 0.726);
            padding: var(--padding_default);
            border-radius: var(--border_radius);
            .quantityInfo{
                .label{
                    font-size: 11px;
                }
                .value{
                    b{ font-size: 16px; }
                }
            }
        }
    }
    .completedCard{
        padding: 5px;
        border-radius: 50px;
        height: 30px;
        width: 30px;
        
    }
}