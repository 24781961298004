
.stagesHeader{
    display: flex;
    // align-items: start;
    height: 50px;
    padding: 0 var(--padding_default);
    .stage{
        padding: var(--padding_custom);
        color: var(--secondary-color);
        font-size: 13px;
        border-radius: 0 0 var(--border_radius) var(--border_radius);
        margin-right: 2px;
        cursor: pointer;
        &:hover{
            color: var(--primary-color_alt);
            background: var(--primary-color-light_alt);
        }
    }
    .activeStage{
        font-weight: bold;
        color: var(--font_color);
        background: var(--background-primary);
        border-top: 2px solid var(--primary-color-light);
        border-bottom: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .stageDetails{
        height: calc(100% - 45px);
    }
}