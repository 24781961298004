@import '/src/index.scss';

.farmSummaryContainer {
    @extend .columnListCard;
    padding: 20px;

    .id {
        color: var(--secondary-color);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .farmValue {
            font-weight: bold;
            font-size: 16px;
            color: var(--font_color_alt);
        }

        .isStatus {
            background: var(--background-gradient-active);
            color: white;
            padding: 5px var(--padding_default);
            border-radius: var(--border_radius);
            margin: var(--margin_default) 0;
            width: fit-content;
        }
    }

    .fstatusWrap {
        height: 150px;
        width: 100%;
        margin-bottom: 10px;
    }

    .statusMeta {
        padding: 5px 0;
        display: flex;
        justify-content: center;
        color: var(--font_color_alt);
    }

    .quantity {
        display: flex;
        border-radius: var(--border_radius);
        padding: var(--padding_custom);
        margin: var(--margin_default) 0;
        align-items: center;

        .icon {
            width: fit-content;
            height: fit-content;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.178);
            margin-right: 20px;
            padding: 15px;

            img {
                width: 35px;
                height: 35px;
            }
        }
    }

    .sub {
        padding: var(--padding_custom);
        background: var(--primary-color-light_alt);
        color: var(--secondary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border_radius);

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .name {
            font-size: 14px;
            font-weight: 600;
            color: var(--font_color_alt);
        }

        .location {
            display: flex;
            align-items: center;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    .farmMeta {
        margin: var(--margin_default) 0;
        padding: var(--padding_custom);
        background: var(--background-secondary);
        border-radius: var(--border_radius);
    }

    .farm {
        padding: var(--padding_default) 0;

        .name {
            font-size: 14px;
            color: var(--secondary-color);
            font-weight: 600;

            b,
            u {
                color: var(--font_color);
            }

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }

        .attr {
            color: var(--secondary-color);

            b {
                color: var(--font_color);
            }
        }
    }
}



.cardSection {
    background: lightgrey;
    border-radius: var(--border_radius);
    padding: 0 40px;
    margin-bottom: var(--margin_default);
    color: white;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;

    .cardImgWrap {
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        background: rgba(252, 251, 251, 0.67);
        margin-right: 20px;
        padding: 15px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    .value {
        color: white;

        .count {
            font-size: 28px;
            font-weight: bold;
        }

        .info {
            text-transform: capitalize;
            font-size: 14px;
        }
    }
}


@media screen and (max-width: 768px) {
    .cardSection {
        padding: 0px 30px;

        .statusInfo {
            .count {
                font-size: 28px;
            }

            .info {
                font-size: 11px;
            }
        }
    }
}