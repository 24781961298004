@import '/src/index.scss';

.allocationGroup{
    @extend .columnListCard;
    background: var(--background-secondary);
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid var(--border-color);
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--padding_custom);
        border-top-left-radius: var(--border_radius);
        border-top-right-radius: var(--border_radius);
    }
    .detailsOpen{
        background: var(--primary-color-light_alt);
        padding-bottom: var(--padding_default);
    }
    .terms{
        font-size: 10px;
        color: red;
    }
    .statusMeta{
        font-size: 11px;
        font-style: italic;
        color: var(--font_color_alt);
        padding: var(--padding_custom);
    }
}
.colData{
    padding: 0 var(--padding_default);
    .meta{
        color: var(--secondary-color);
        font-size: 11px;
        i{
            color: var(--secondary-color);
            font-size: 10px;
            text-transform: capitalize;
        }
        b{
            color: var(--font_color_alt);
        }
    }
    .method{
        color: var(--font_color_alt);
    }
    .tss{
        color: var(--primary-color-light);
    }
    .highlights{
        font-size: 14px;
        font-weight: bold;
        color: var(--font_color_alt);
        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }
}
.collectionCol{
    padding: var(--padding_default);
    background: white;
    border-radius: var(--border_radius);
    margin: 0 5px 5px 0;
    .openCollectionDetailImg{
        width: 15px;
        height: 15px;
    }
}
.allocationGroup{
    border-top: 1px solid var(--primary-color-light);
    .collection{
        border-radius: var(--border_radius);
        padding: var(--padding_default);
        .section{
            padding: var(--padding_default) 15px;
            color: var(--font-color);
        }
        .quantity{
            background: var(--primary-color-light_alt);
        }
        .inputGroup{
            display: flex;
            align-items: center;
            input{
                width: 70px;
                padding: 5px 10px;
                border: 1px solid var(--primary-color-light);
                border-radius: var(--border_radius);
            }
            img{
                height: 30px;
                width: 30px;
                cursor: pointer;
                margin-left: 5px;
            }
        }
    }
    .completedCard{
        padding: 5px;
        border-radius: 50px;
        height: 30px;
        width: 30px;
        
    }
}

.collectionUnits{
    width: 80%;
}
.collectionActions{
    width: 20%;
    text-align: right;
    .pending{
        padding: 5px 0;
        font-size: 10px;
        b{
            font-size: 12px;
        }
    }
}

.collectionMeta{
    font-size: 14px !important;
    background: var(--primary-color-light_alt);
    margin: var(--margin_default);
    border-radius: var(--border_radius);
    .attr{
        padding: 5px 0;
        i{
            font-size: 10px;
        }
    }
}