@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  --primary-color-light: #CDD971;
  --primary-color-light_alt: #F2F4E3;
  --font_color: #365219;
  --font_color_alt: #67903C;
  --secondary-color: #8699ad;
  --background-primary: #f6f6f6;
  --background-secondary: #f9f9f9;
  --border-color: #eeeeee;
  --border_radius: 15px;
  --margin_default: 10px;
  --padding_default: 10px;
  --padding_custom: 15px 20px;
  --background-gradient: linear-gradient(45deg, var(--font_color_alt) 0%, var(--primary-color-light) 100%);
  --background-gradient-completed: linear-gradient(45deg, var(--font_color_alt) 0%, #84cf34 100%);
  --background-gradient-active: linear-gradient(45deg, orange 0%, #fada9f 100%);
  --background-gradient-new: linear-gradient(45deg, #2655d4 0%, #b4c6f7 100%);
  --background-gradient-neutral: linear-gradient(45deg, var(--secondary-color) 0%, #b4c6f7 100%);
  --box-shadow: 0 3px 6px rgb(0 0 0 / 10%), 0 3px 6px rgb(0 0 0 / 15%);
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #cacfde;
  border: 2px solid white;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-primary);
  box-shadow: none;
}

input:focus-visible {
  outline: none;
}

.seperator {
  width: 100px;
  height: 5px;
  background: var(--background-gradient-completed);
}

.disabledItem {
  pointer-events: none;
  opacity: 0.5;
}

.flex {
  display: flex !important;
}

.displayBlock {
  display: block !important;
}

.scrollable {
  overflow: auto !important;
}

.inline {
  display: inline-block !important;
}

.maxHeight {
  height: 100% !important;
}

.maxWidth {
  width: 100% !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.floatRight {
  float: right !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.alignRight {
  align-items: end;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.displayNone {
  display: none !important;
  opacity: 0 !important;
  overflow: hidden !important;
}

.justify-align-center {
  justify-content: center !important;
  align-items: center;
}

.input-wrapper {
  color: var(--primary-color-dark);
  margin: 15px 0 !important;

  label {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 13px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    border: 1px solid var(--border-color);

    &:active {
      outline: none;
      border: 1px solid var(--secondary-color);
    }
  }

  .dropdownInput {
    width: 100%;
    font-size: 13px;
    border: none;
    padding: 0 !important;

    &:active {
      outline: none;
      border: none;
    }
  }

  .dropdownMenu {
    font-size: 13px;
  }
}

.link {
  &:hover {
    color: #2655d4;
    cursor: pointer;
  }
}



.columnClass {
  width: 25%;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.SPAWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: var(--background-primary);
  position: relative;

  .mobileNavToggler {
    display: none;
    top: 20px;
    left: 10px;
    z-index: 1;

    img {
      height: 25px;
      width: 25px;
    }
  }

  .sidenavWrap {
    width: 15%;
  }

  .sidenavMobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-color-light);
    opacity: 0;
    width: 0;

    .sidenavMenu {
      display: none;
    }
  }

  .sidenavMobileOpen {
    transition: all 0.2s ease-in;
    width: 70%;
    z-index: 2;
    opacity: 1;

    .sidenavMenu {
      display: unset !important;
    }
  }

  .contentWrap {
    width: 85%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding_default);
    height: 8%;

    .backBtn {
      display: flex;
      align-items: center;
      padding: var(--padding_custom);
      border-radius: var(--border_radius);
      background: var(--primary-color-light_alt);
      color: var(--font_color_alt);
      margin-right: 10px;
      text-transform: uppercase;
      cursor: pointer;

      .backIcon {
        height: 10px;
        margin-right: 5px;
      }
    }

    .contentHeaderGroup {
      display: flex;
      flex-direction: column;

      .contentHeaderTitle {
        font-size: 16px;
        font-weight: 600;
        color: var(--font_color);
        display: flex;
        .contentHeaderIcon {
          height: 20px;
          width: 20px;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      .contentHeaderSubTitle {
        color: var(--secondary-color);
      }
    }
  }

  .contentBody {
    height: 92%;
    overflow: auto;
    display: block;
  }
}
.inlineColumn{
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.mobilesearchWrap{
  display: none;
  .searchIcon{
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  // width: 100%;
}

.mobilesearchContentWrap{
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #ffffffb8;
  z-index: 10;
  left: 0;
  top: 0;
  padding: 20px;
  .searchBar{
    background: white;
    height: 40px;
    border: 1px solid var(--font_color);
  }
  .searchContent{
    width: calc(100% - 40px);
    top: 65px;
    max-height: 90%;
  }
}
.searchWrap {
  width: 30vw;
  height: fit-content;
  margin: 0 var(--margin_default);
  position: relative;
}
.searchBar {
  width: 100%;
  height: 100%;
  padding: var(--padding_default) 20px;
  border-radius: var(--border_radius);
  color: var(--font_color);
  background: var(--border-color);
  display: flex;
  align-items: center;
  flex-grow: 1;

  &:focus {
    outline: none;
    background: white;
    transition: all 0.2s ease-in;
  }
  .removeSearch{
    position: absolute;
    height: 20px;
    right: 20px;
    cursor: pointer;
  }
  .searchIcon {
    height: 20px;
    margin-right: 10px;
  }

  input {
    font-size: 12px;
    outline: none;
    border: none;
    background: transparent;
    height: fit-content !important;
    padding: 0 !important;
  }
}
.searchContent {
  width:100%;
  max-height: 50vh;
  overflow: auto;
  z-index: 1000; 
  position: absolute;
  background-color: white;
  top: 50px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border_radius);
  .searchResult{
    padding: 1vw;
    cursor: pointer;
    &:hover{
      background: var(--background-secondary);
    }
    .title{
      font-weight: bold;
      font-size: 14px;
    }
    .subtitle{
      font-size: 11px;
      color: var(--secondary-color);
      text-transform: capitalize;
      .date{
          color: var(--font_color_alt);
          font-weight: 600;
      }
    }
    .status{
      font-size: 10px;
      color: white;
      padding: 5px;
      border-radius: 5px;
    }
  }
}




.columnListCard {
  background: white;
  border-radius: var(--border_radius);
  margin: var(--margin_default);
  width: calc(100% - 2*var(--margin_default));
  height: calc(100% - 2*var(--margin_default));
  display: flex;
  flex-direction: column;
}

.primaryBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding_default) 20px;
  border-radius: var(--border_radius);
  background: var(--background-gradient);
  color: white;
  font-weight: bold;
  font-variant: normal;
  cursor: pointer;
  width: fit-content;
  text-transform: capitalize;

  &:hover {
    opacity: 0.8;
  }
}

.secondaryBtn {
  display: flex;
  align-items: center;
  padding: var(--padding_default) 20px;
  border-radius: var(--border_radius);
  background: var(--secondary-color);
  color: white;
  font-weight: bold;
  font-variant: normal;
  cursor: pointer;
  width: fit-content;
  text-transform: capitalize;

  &:hover {
    opacity: 0.8;
  }
}

.tertiaryBtn {
  display: flex;
  align-items: center;
  padding: var(--padding_default) 20px;
  border-radius: var(--border_radius);
  background: #f9f9f9;
  border: 1px solid var(--border-color);
  color: var(--font_color);
  font-weight: bold;
  font-variant: normal;
  cursor: pointer;
  width: fit-content;
  text-transform: capitalize;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.removeBtn {
  padding: 5px 10px;
  font-size: 10px;
  font-weight: normal;
  background: rgb(248, 204, 204);
  color: red;
  cursor: pointer;
  margin: 5px 0;
}

.completedCard {
  background: var(--background-gradient-completed);
}

.activeCard {
  background: var(--background-gradient-active);
}

.newCard {
  background: var(--background-gradient-new);
}

.completeColor {
  color: #84cf34;
}

.activeColor {
  color: orange;
}

.errorColor {
  color: red;
}

.submitBtn {
  @extend .primaryBtn;
  font-size: 14px;
}

.small {
  font-variant: small-caps;
}

@media screen and (max-width: 1850px) {
  .SPAWrap {
    .sidenavWrap {
      width: 20%;
    }

    .contentWrap {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1400px) {
.columnClass {
    // width: calc(50% - 2*var(--margin_default));
    width: 50%;
    // height: auto;
    // max-height: 100vh;
  }
}

@media screen and (max-width: 1000px) {
  .mobilesearchWrap{
    display: block !important;
  }
  .searchWrap{
    display: none !important;
  }
  .SPAWrap {
    .sidenavWrap {
      width: 25%;
    }

    .contentWrap {
      width: 75%;
    }
  }

  .columnClass {
    // width: calc(100% - 2*var(--margin_default));
    width: 100%;
    height: fit-content;
  }

  .contentContainer {
    .contentHeader {
      .backBtn {
        span {
          display: none;
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  #root {
    font-size: 11px;
    --border_radius: 10px;
    --margin_default: 5px;
    --padding_default: 7px;
    --padding_custom: 8px 12px;
  }

  .contentContainer {
    .contentHeader {
      padding: 10px 10px 10px 50px;

      .backBtn {
        padding: 10px;
        margin-right: 5px;
        display: none;
        .backIcon {
          height: 10px;
          margin-right: 5px;
        }
      }

      .contentHeaderGroup {
        .contentHeaderTitle {
          font-size: 12px;
          .text{
            width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    .contentBody {
      height: 92%;
      // overflow: auto;
      // display: unset;
    }
  }


  .SPAWrap {
    .mobileNavToggler {
      display: unset;
    }

    .sidenavWrap {
      width: 0;
      overflow: hidden;
      opacity: 0;
    }

    .sidenavMobile {
      display: unset;
    }

    .contentWrap {
      width: 100%;
    }
  }

  .primaryBtn {
    padding: var(--padding_default) 15px;
  }

  .submitBtn {
    font-size: 12px;
  }
}


.blueHighlight {
  color: #324de4 !important;

  b {
    color: #324de4 !important;
  }
}

.batchProgressDescription {
  display: flex;
  justify-content: center;

  .statusDesc {
    background: #e6fbdc !important;
    color: green !important;
    font-weight: bold;
  }
}

.statusDesc {
  padding: var(--padding_default) 20px;
  margin: 0 var(--margin_default);
  font-size: 12px;
  text-transform: capitalize;
  color: #d48900;
  width: fit-content;
  background: rgb(253, 227, 227);
  border-radius: var(--border_radius);
}



















.locationWrap {
  display: flex;
  padding: var(--padding_default);

  .location {
    img {
      width: 20px;
      height: 20px;
    }

    b {
      font-size: 13px;
    }
  }
}


.dmsDocViewer{
  width: 100%;
  height: 80vh;
  .docviewheader{
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: var(--font_color);
    img{
      height: 30px;
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
      background: #eee;
      padding: 5px;
      border-radius: var(--border_radius);
    }
  }
  .pdfContainer {
    height: calc(100% - 50px);
    width: 100%;
    text-align: center;
    overflow: auto;
    img{
      height: fit-content;
      max-width: 100%;
      min-width: 50%;
    }
  }
}
.pdfViewer .page .loadingIcon {
  background-image: none !important;
}
.documentContainer .footerToolbar {
  display: none !important;
}


.dialogFormContainer {
  .formBody {
    max-height: calc(100vh - 40px) !important;
  }
}

.formContainer {
  width: 100%;

  // flex-grow: 1;

  .formHeader {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding: 0 15px;
    height: 40px;

    .formHeaderTitle {
      font-size: 15px;
      font-weight: 600;
      color: var(--font_color_alt);

      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }

  .formBody {
    max-height: 80vh;
    overflow: auto;
    padding-bottom: var(--padding_default);
    padding: var(--padding_custom);

    .attr {
      color: var(--secondary-color);

      b {
        color: var(--font_color);
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        align-items: center;
      }
    }

    
p {
  font-size: 14px;
}

.meta-part {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 10px;
  // border: 1px solid #eee;

  .meta-name {
      font-size: 14px;
      padding: 5px 0;
      font-weight: 500;
  }

  .meta-para {
      font-size: 10px;

  }
}

.bullets {
  font-size: 11px;
  padding: 5px 0 5px 15px;

  li::before {
      display: inline-block;
      width: 1em;
      margin-left: -1em
  }
}
  }

  .formButtonGroup {
    display: flex;
    justify-content: center;
    padding: var(--padding_default);

    .formBtn {
      margin: 0 5px;
    }
  }

  .formTerms {
    display: flex;
    padding: var(--padding_default);
    margin: 0 5px;
    flex-direction: row;

    .termsText {
      color: red;
      padding: 7px var(--padding_default);

      u {
        font-weight: bold;
        font-size: 16px;

        &:hover {
          color: #2655d4;
          cursor: pointer;
        }
      }
    }
  }
}

.docSummaryWrap {
  .docSummary {
    padding: var(--padding_default);
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-weight: bold;
      color: var(--font_color);
    }

    u{
      font-style: italic;
      color: var(--font_color_alt);
      cursor: pointer;
      
    }
    span {
      color: var(--secondary-color);
    }
  }
}


// *************************rjsf overrides**********************
.field .control-label {
  text-transform: uppercase;
  font-weight: bold;
}

.field-description {
  color: #8699ad;
  margin: 0;
}

.MuiInputBase-formControl {
  background: #f9f9f9;
  border: none !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}


// *************************mui overrides**********************
.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.css-1c35hjw-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  border-radius: var(--border_radius);
  background: var(--background-secondary);
  // border: 1px solid var(--primary-color-light);
}

.field-object {
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 15px;

  // margin-bottom: 10px;
  .field {

    input,
    select {
      //padding: 0 0.5vw;
      padding: 10px;
      border-radius: 15px;
      background: #f6f6f6;
      border: none !important;

      &:focus {
        border: 1px solid #8699ad;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    input:focus-visible,
    select:focus-visible {
      outline: none;
    }

    textarea {
      padding: 10px;
      border-radius: 15px;
      min-height: 70px;

      &:focus {
        border: 1px solid #8699ad;
        outline: none;
        box-shadow: none;
      }
    }

    textarea:focus-visible {
      outline: none;
    }
  }

  h5 {
    font-size: 12px;
  }
}

textarea {
  padding: 10px;
  border-radius: 15px;

  &:focus {
    // border: 1px solid var(#8699ad);
    outline: none;
    box-shadow: none;
  }
}

textarea:focus-visible {
  outline: none;
}

.MuiAutocomplete-input {
  padding: 0 !important;
}

input {
  height: 40px !important;
  padding: 10px;
  border-radius: 15px;
  // &:focus{
  //     // border: 1px solid var(#8699ad);
  //     outline: none !important;
  //     box-shadow: none !important;
  // }
}

input:focus-visible {
  outline: none;
}


.custom_location {
  .meta {
    color: var(--secondary-color);
    padding: 2px 0;

    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .location {
    display: flex;
    align-items: center;
  }
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  .modalCancel {
    top: 15px;
    right: 15px;
    height: 30px;
    width: 30px;
    background: rgb(250 223 223 / 80%);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 10;
  }

  //media queries
  @media screen and (max-width: 1000px) {
    padding: 10px;
  }
}

.drawerCancel {
  right: 15px;
  top: 20px;
  width: 20px;
}



.createHeadertBtn {
  @extend .primaryBtn;
  font-size: 14px;
  margin: 0 var(--margin_default);
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}


/** for common registration form */

.offcanvas {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  transition: transform 0.25s ease-in-out;
  box-shadow: var(--box-shadow);
  background: white;
  border-radius: var(--border_radius);
  top: 0;
  right: 0;
  width: 425px;
  transform: translateX(100%);

  .show {
    visibility: visible;
  }

}